import React, { Component } from 'react'
import './style.scss'
import { ImpulseSpinner } from "react-spinners-kit";

export default class LoadingPage extends Component {
    render() {
        return (
            <div className={`${this.props.isOpen ? "warpper-loading-page" : "d-none"}`}>
                <div style={{ width: "10%" }} className="d-flex align-items-center flex-col">
                    <img src={`${process.env.PUBLIC_URL}/asset/img/logo.svg`} alt="" />
                    <ImpulseSpinner
                        size={60}
                        frontColor="#e54a26"
                        backColor="#ffd600"
                        loading={true}
                    />
                </div>
            </div>
        )
    }
}

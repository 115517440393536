import React, { Component } from 'react'
import {
    Container,
    Row,
    Form,
    FormGroup,
    Input,
    Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import Service from '../../Script/service';
import { ImpulseSpinner } from "react-spinners-kit";
import Moment from 'react-moment';

export default class Recipes extends Component {
    constructor(props) {
        super(props);
        this.textHeader = React.createRef();
        this.state = {
            dropdownOpen: false,
            data: {}
        }
    }
    componentDidMount() {
        Service.getBlog({ cat_name: "recipe", limit: 9 })
            .then(res => {
                this.setState({ data: res.data })
            })
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (window.location.pathname === `${process.env.PUBLIC_URL}/recipe`) {
            if (this.textHeader.current !== null) {
                this.textHeader.current.style.marginTop = `${window.scrollY / 2}px`
            }
        }
    };
    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    handleChangePagination = (activePage) => {
        this.setState({ data: { ...this.state.data, data: undefined } })
        let _parseInt = parseInt(activePage)
        Service.getBlog({ cat_name: "recipe", page: _parseInt, limit: 9 })
            .then(response => {
                this.setState({ data: response.data })
            })
    }

    handleSearch = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.setState({ data: { ...this.state.data, data: undefined } })
            Service.getBlog({ cat_name: "recipe", limit: 9, search: e.target.value })
                .then(response => {
                    this.setState({ data: response.data })
                })
        }

    }

    render() {
        return (
            <div>
                <section className="bg-header-our-product mb-5">
                    <Container className="h-100 position-relative">
                        <div className="text-header-area w-50">
                            <div className="d-flex justify-content-center pb-3">
                                <div className="d-inline-block">
                                    <h1 className="text-header-animation" ref={this.textHeader}>Recipes</h1>
                                    <div className="underline-animation-1" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Breadcrumb>
                                    <BreadcrumbItem><strong>หน้าแรก</strong></BreadcrumbItem>
                                    <BreadcrumbItem active><strong>สูตรอาหาร</strong></BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                            <Form className="position-relative">
                                <i className="icon-search-ele position-absolute material-icons-outlined" >search</i>
                                <FormGroup className="m-0">
                                    <Input type="search" name="search" id="search" placeholder="ค้นหาบทความ" className="pl-5" onKeyDown={(e) => this.handleSearch(e)} />
                                </FormGroup>
                            </Form>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/chef-moo.svg`} className="pos-absolute animated fadeIn slow d-none d-xl-block" style={{height: "140%", top: "50px", right: 0 }} alt="" />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/recipes/recipe-ingred.png`} className="pos-absolute animated fadeIn slow d-none d-xl-block h-75" style={{ marginLeft:"-100px", position:"absolute", top: "60%", transform: "translateY(-40%) rotate(45deg)" }} alt="" />
                    </Container>
                </section>
                <section>
                    <Container>
                        
                        {
                            this.state.data.data &&
                            <Row>
                                {
                                    this.state.data.data.map((items, index) => {
                                        return (
                                            <div className="col-lg-4 col-md-6 col-12" key={index}>
                                                <div className="my-3 warpper-card">
                                                    <NavLink to={`/single-post/${items.sub_url_name}`}>
                                                        <div>
                                                            <div className=" mb-3">
                                                                <img src={items.img_blog_thumbnail} className="img-fluid" alt="" />
                                                            </div>
                                                            <h5 className="mt-0 mb-2 px-3 text-title-blog text-blue" dangerouslySetInnerHTML={{ __html: items.title }}></h5>
                                                            <small className="body-1 text-descriptionmore px-3" dangerouslySetInnerHTML={{ __html: items.descriptionmore }}></small>
                                                        </div>
                                                        <div className="py-3 px-3 d-flex align-items-center">
                                                            <div className="img-thumnail">
                                                                <img src={items.authornameimage} alt="" />
                                                            </div>
                                                            <small className="caption">{items.authorname} | <Moment format="DD MMM YYYY">{items.date}</Moment> </small>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Row>
                        }
                        <div className="d-flex justify-content-center py-5">
                            <ImpulseSpinner
                                size={60}
                                frontColor="#e54a26"
                                backColor="#ffd600"
                                loading={this.state.data.data === undefined}
                            />
                            {
                                this.state.data.data !== undefined &&
                                this.state.data.data.length === 0 && (
                                    <h1>Coming Soon ...</h1>
                                )
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            <Pagination current={this.state.data.paged} total={this.state.data.max_num_pages} onChangePage={this.handleChangePagination} />
                        </div>
                    </Container>
                </section>
            </div>
        )
    }
}

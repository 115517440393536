import React, { Component } from 'react'
import './style.scss'
export default class ButtonBubble extends Component {
    render() {
        return (
            <div {...this.props}>
                <div className="btn btn-bubble ">{this.props.children}</div>
            </div>
        )
    }
}

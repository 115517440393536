import axios from 'axios';
const apiPath = "https://chefmoopurelard.com/admin/wp-json/api/"
// const apiPath = "https://itreelife.com/chef_moo/admin/wp-json/api/"
const Service = {
    getBlog(e) {
        return axios.post(apiPath + 'getblog', e);
    },
    getSingleBlog(e) {
        return axios.post(apiPath + 'getsingleblog', e);
    },
    getRelatedBlog(e) {
        return axios.post(apiPath + 'getrelatedblog', e)
    },
    sendMailContact(e) {
        return axios.post(apiPath + 'sendmailcontact', e);
    },
    sendMailProduct(e) {
        return axios.post(apiPath + 'sendmailcontactproduct', e);
    }
}
export default Service
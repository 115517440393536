import React, { Component } from 'react'
import {
    Container,
    Row
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './style.scss';
import Button from '../Button';
import Service from '../../Script/service';
import Moment from 'react-moment';
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }
    componentDidMount() {
        window.scrollTo({top:0})
        Service.getBlog({ cat_name: "blog", limit: 4 })
            .then(res => {
                this.setState({ data: res.data })

            })
    }
    
    render() {
        const { data } = this.state
        return (
            <Container className="py-5">
                <h1 className="text-blue header header-responsive text-center">Lasted Blog</h1>
                <div className="underline-header"></div>
                <div className="underline-section mb-2"></div>
                <Row className="py-3">
                    <div className="col-lg-5">
                        {
                            data.data &&
                            <div className="my-3 warpper-card">
                                <NavLink to={`/single-post/${data.data[0].sub_url_name}`}>
                                    <div>
                                        <div className=" mb-3">
                                            <img src={data.data[0].img_blog_thumbnail} className="w-100" alt="" />
                                        </div>
                                        <h5 className="mt-0 mb-2 px-3 text-title-blog text-blue" dangerouslySetInnerHTML={{ __html: data.data[0].title }}></h5>
                                        <small className="body-1 text-descriptionmore px-3" dangerouslySetInnerHTML={{ __html: data.data[0].descriptionmore }}></small>
                                    </div>
                                    <div className="py-3 px-3 d-flex align-items-center">
                                        <div className="img-thumnail">
                                            <img src={data.data[0].authornameimage} alt="" />
                                        </div>
                                        <small className="caption">{data.data[0].authorname} | <Moment format="DD MMM YYYY">{data.data[0].date}</Moment> </small>
                                    </div>
                                </NavLink>
                            </div>
                        }
                    </div>
                    <div className="col-lg-7">
                        {
                            data.data &&
                            data.data.map((items, index) => {
                                return (
                                    index > 0 &&
                                    <NavLink to={`/single-post/${items.sub_url_name}`} key={index}>
                                        <div className="my-3 warpper-card-responsive">
                                            <div className="row">
                                                <div className="col-12 col-md-6 px-0">
                                                    <div className="hov-img-zoom">
                                                        <img src={items.img_blog_thumbnail} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 pt-3 py-lg-3">
                                                    <h5 className="mt-0 mr-md-3 px-3 px-md-0 mb-2 text-title-blog text-blue" dangerouslySetInnerHTML={{ __html: items.title }}></h5>
                                                    <small className="body-1 text-descriptionmore px-3 px-md-0 mr-md-3" dangerouslySetInnerHTML={{ __html: items.descriptionmore }}></small>
                                                    <div className="py-3 px-3 px-md-0 d-flex align-items-center">
                                                        <div className="img-thumnail">
                                                            <img src={items.authornameimage} alt="" />
                                                        </div>
                                                        <small className="caption">{items.authorname} | <Moment format="DD MMM YYYY">{items.date}</Moment> </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                )
                            })
                        }
                    </div>

                    <div className="col-12 text-center">
                        <NavLink to="/blog" className="d-inline-block">
                            <Button>ดูเพิ่มเติม</Button>
                        </NavLink>
                    </div>
                </Row>

            </Container>
        )
    }
}

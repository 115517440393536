import React, { Component } from 'react'
import {
    Container
} from 'reactstrap';
export default class Aboutus extends Component {
    constructor(props) {
        super(props);
        this.textHeader = React.createRef();
    }
    componentDidMount() {
        window.scrollTo({ top: 0 })
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (window.location.pathname === `${process.env.PUBLIC_URL}/aboutus`) {
            if (this.textHeader.current !== null) {
                this.textHeader.current.style.marginTop = `${window.scrollY / 2}px`
            }
        }
    };
    render() {
        return (
            <div>
                <section className="bg-header-our-product mb-5">
                    <Container className="h-100 position-relative">
                        <div className="text-header-area ">
                            <h1 className="text-header-animation" ref={this.textHeader}>About Us</h1>
                            <div className="underline-animation-1" />
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/about-us/02-chef-moo.svg`} className="pos-absolute animated fadeIn slow d-none d-xl-block" style={{ height: "140%", top: "50px", left: 0 }} alt="" />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/product-chefmoo.png`} className="pos-absolute animated fadeIn slow d-none d-xl-block" style={{ height: "150%", top: "60px", right: 0 }} alt="" />
                    </Container>
                </section>
                <section>
                    <Container>
                        <div className="text-center pb-3">
                            <img src={`${process.env.PUBLIC_URL}/asset/img/logo.svg`} height="120px" alt="" />
                            <h2>CHEF MOO</h2>
                        </div>
                        <div className="d-block d-lg-flex pb-3">
                            <div className="px-0 pl-lg-0 pr-lg-3 col-12 col-lg-7 text-indent d-flex flex-column justify-content-center">
                                <p className="cs-prajad">
                                    เดิมแล้วครอบครัวของผู้บริหารเติบโตมาด้วยธุรกิจเขียงหมูจากคนต่างจังหวัดที่เข้ามาหาโอกาสในเมืองทำงาน
                                </p>
                                <p className="cs-prajad">
                                    บริษัทนี้จัดตั้งโดยคุณสมพจน์ ธนพัฒน์ภิญโญ ซึ่งเป็นคนปทุมธานีเข้ามาในสมุทรปราการเพื่อช่วยพี่สาวทำงานโดยแรกสุดได้ขายของสดและผักอยู่ในตลาดสดปากน้ำจากนั้นก็ได้มีโอกาสเปิดเขียงหมูกับพี่สาวขึ้นและ จากความขยันขันแข็งและมุมานะทำให้พัฒนาจากเขียงหมูก็กลายเป็นบริษัทจากบริษัทก็ค่อยค่อยเริ่มเปิดโรงงานจากโรงงานเล็กๆก็ต่อยอดเรื่อยมาจนเป็นระบบและเป็นซัพพลายเออร์ให้กับห้างใหญ่เช่นแม็คโครและโลตัสทั่วประเทศไทย
                                </p>
                                <p className="cs-prajad">
                                    คุณสมพจน์เริ่มกิจการเขียงหมูพร้อมกับพี่สาวจากหมูวันละสามตัวจนปัจจุบันมียอดการชำแหละกว่า 3000 ตัวในหนึ่งวันด้วยเครื่องจักรและเทคโนโลยีที่ทันสมัยทำให้คุณภาพของวัตถุดิบที่โรงหมูซึ่งมีอายุยาวนานกว่า 30 ปีได้มาตรฐานรับรองสากลทุกแขนง
                                </p>
                            </div>
                            <div className="text-center pb-3 pb-lg-0 col-12 col-lg-5 px-0">
                                <img src={`${process.env.PUBLIC_URL}/asset/img/about-us/inigo-de-la-maza-LQJg_PXPPUs-unsplash.jpg`} className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="d-block d-lg-flex pb-3">
                            <div className="text-center pb-3 pb-lg-0 col-12 col-lg-5 px-0">
                                <img src={`${process.env.PUBLIC_URL}/asset/img/about-us/SPC_5320.jpg`} className="img-fluid" alt="" />
                            </div>
                            <div className="px-0 pr-lg-0 pl-lg-3 col-12 col-lg-7 text-indent d-flex flex-column justify-content-center">
                                <p className="cs-prajad">
                                    และแล้วคุณสมพจน์ก็ได้เล็งเห็นถึงคุณประโยชน์ของวัตถุดิบมันหมูซึ่งมักจะเป็นสินค้าที่ถูกประเมินราคาและตีราคาให้ต่ำ โดยให้โรงงานมักจะจำแนกว่าเป็นสินค้าที่ทำกำไรได้น้อย จึงเกิดการศึกษาข้อมูลและหาข้อเท็จจริงว่าสินค้าจากมันหมูนั้นจะเป็นประโยชน์ต่อผู้บริโภคมากน้อยแค่ไหนทำให้เราได้ศึกษาข้อมูลจากหลากหลายประเทศและพบว่าน้ำมันหมูนี่แหละคือสินค้าที่อยู่คู่กับผู้คนมาเนิ่นนานกว่า 1000 ปีเป็นวัตถุดิบที่ถูกมองข้ามและถูกตีตราให้เป็นวัตถุดิบที่ทำให้คนอ้วนซึ่งในความเป็นจริงแล้วน้ำมันหมูที่เป็นธรรมชาตินั้นกลับส่งผลให้สุขภาพแข็งแรงและผู้ที่บริโภคน้ำมันหมูเป็นประจำกลับไม่พบโรคใดใด
                                </p>
                                <p className="cs-prajad">
                                    คุณสมพจน์จึงได้ตั้งเป้าหมายว่าจะต้องทำโรงงานสำหรับผลิตผลิตภัณฑ์จสกมันหมูเกรดพรีเมี่ยม เพื่อส่งออกต่างประเทศให้คนทั่วโลกได้รู้ว่าน้ำมันหมูนั้นคือของดีที่คนควรจะใช้บริโภคในชีวิตประจำวัน
                                </p>
                            </div>
                        </div>

                        <div className="d-block d-lg-flex pb-3">
                            <div className="px-0 pl-lg-0 pr-lg-3 col-12 col-lg-7 text-indent d-flex flex-column justify-content-center">
                                <p className="cs-prajad">
                                    โรงงานได้เริ่มก่อตั้งในปี 2559 โดยเริ่มจากทีมงานในครอบครัวจากก้าวแรกที่เล็กๆก็มีเสียงตอบรับออกมาอย่างหลากหลายและสร้างความเปลี่ยนแปลงที่ยิ่งใหญ่ขึ้นในสังคมผู้รักสุขภาพเพราะการตั้งโรงงานผลิตน้ำมันหมูในครั้งนี้คำนึงถึงผลประโยชน์ของผู้บริโภคและความสะดวกสบายให้ผู้บริโภคได้ใช้น้ำมันหมูคุณภาพดีในราคาที่คุ้มค่าที่สุด
                                </p>
                                <p className="cs-prajad">
                                    โดยสิ่งที่เรามั่นใจที่สุดคือวัตถุดิบคุณภาพที่ออกใหม่ทุกวันจากโรงชำแหละซึ่งเป็นธุรกิจใหญ่ในครอบครัวและกระบวนการผลิตรวมถึง package ที่มีทีมงานและนักวิจัยมืออาชีพคอยออกแบบให้
                                </p>
                            </div>
                            <div className="text-center pb-3 pb-lg-0 col-12 col-lg-5 px-0">
                                <img src={`${process.env.PUBLIC_URL}/asset/img/about-us/SPC_5587-Edit.jpg`} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        )
    }
}

import React, { Component } from 'react'
import 'animate.css'
export default class Touch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            state_touch: false
        }
        this.scrollEle = React.createRef()
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (this.scrollEle.current) {
            if (this.props.positionDetect === "down") {
                if (window.scrollY > this.scrollEle.current.offsetTop + (this.scrollEle.current.offsetHeight * (this.props.peract / 100)) && !this.state.state_touch) {
                    this.props.funcact && this.props.funcact()
                    this.setState({ state_touch: true })
                }
            } else if (this.props.positionDetect === "up") {
                if (window.scrollY > this.scrollEle.current.offsetTop - (this.scrollEle.current.offsetHeight * (this.props.peract / 100)) && !this.state.state_touch) {
                    this.props.funcact && this.props.funcact()
                    this.setState({ state_touch: true })
                }
            }
        }

    }
    render() {
        return (

            <div ref={this.scrollEle} className={`${this.state.state_touch && this.props.animate} ${this.props.className ? this.props.className : ""} ${this.props.funcact === undefined && (this.state.state_touch ? "visible" : "invisible")}`}>
                {
                    this.props.children
                }
            </div>
        )
    }
}

import React, { Component } from 'react';
// import { Pagination } from 'semantic-ui-react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './style.scss'
export default class PaginationComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disableLeft: false,
            disableRight: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.handleInit(nextProps)
    }

    handlePage = (page, current) => {
        page !== current &&
            this.props.onChangePage(page)

    }

    handleInit = (page) => {
        this.setState({
            disableLeft: parseInt(page.current) === 1,
            disableRight: parseInt(page.total) === parseInt(page.current)
        })
    }

    handleArrow = (statePage) => {

        const _changePage = parseInt(this.props.current) + statePage;
        ((_changePage >= 1) && (_changePage <= parseInt(this.props.total))) &&
            this.props.onChangePage(_changePage)
    }

    render() {
        const pageNumber = []
        if (this.props.total > 3) {
            if (this.props.current <= 3) {
                for (let i = 1; i <= 3; i++) {
                    pageNumber.push(`${i}`)
                }
                pageNumber.push(`...`)
            } else if (this.props.current > this.props.total - 3) {
                pageNumber.push(`...`)
                for (let i = this.props.total - 2; i <= this.props.total; i++) {
                    pageNumber.push(`${i}`)
                }
            } else {
                pageNumber.push(`...`)
                for (let i = this.props.current - 1; i <= this.props.current + 1; i++) {
                    pageNumber.push(`${i}`)
                }
                pageNumber.push(`...`)
            }
        } else {
            for (let i = 1; i <= this.props.total; i++) {
                pageNumber.push(`${i}`)
            }
        }
        const renderNumberPage = pageNumber.map((value, index) => {
            return (
                <PaginationItem key={index} active={`${this.props.current}` === value} onClick={() => this.handlePage(value, this.props.current)}>
                    <PaginationLink >
                        {value}
                    </PaginationLink>
                </PaginationItem>
            )
        })
        return (
            <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={this.state.disableLeft} onClick={() => this.handleArrow(-1)}>
                    <PaginationLink previous />
                </PaginationItem>
                {renderNumberPage}
                <PaginationItem disabled={this.state.disableRight} onClick={() => this.handleArrow(1)}>
                    <PaginationLink next />
                </PaginationItem>
            </Pagination>
        );
    }
}
import React, { Component } from 'react'
import {
    Container,
    Row
} from 'reactstrap';

const detailProduct = [{
    img: `${process.env.PUBLIC_URL}/asset/img/our-product/imageGroup/cooked-food-on-plate.jpg`,
    h: "เลือกสรรวัตถุดิบดี",
    s: "ตอนนี้ทางโรงงานมีมาตราฐาน Primary GMP ที่รับรองโดยกระทรวงสาธารณะสุข ว่ากระบวนการและขั้นตอนนั้น ปลอดภัย ทำให้ผลิตสินค้าที่มีคุณภาพมาตรฐานออกสู่ตลาดได้"
}, {
    img: `${process.env.PUBLIC_URL}/asset/img/our-product/imageGroup/01cooked-food-on-plate.jpg`,
    h: "ความสดใน 2 นาที",
    s: "รับวัตถุดิบสดใหม่ทุกวัน เพื่อคัดสรร น้ำมันเกรดพรีเมี่ยม ให้คงคุณค่าไว้ได้นาน"
}, {
    img: `${process.env.PUBLIC_URL}/asset/img/our-product/imageGroup/cooked-food-on-plate.jpg`,
    h: "คัดแยกและเตรียมการ",
    s: "วัตถุดิบของเราสดใหม่ เนื่องจากได้รับวัตถุดิบที่ ที่ส่งตรงมาจากโรงงานแปรรูปของเราเอง และ น้ำมันหมูของเรายังผ่านการคัดเอาส่วนไขมัน ออก โดยทั่วไปเมื่อเราเจียวน้ำมันหมู ปล่อยไว้ระยะหนึ่งจะเกิดตะกอน ซึ่งเป็นไขมันหนักข้างใต้ ซึ่งส่วนนี้ทางโรงงานได้คัดแยกออก ให้เหลือเพียงน้ำมันส่วนที่ดีที่สุดซึ่งร่างกายสามารถย่อยได้ง่ายกว่า"
}, {
    img: `${process.env.PUBLIC_URL}/asset/img/our-product/imageGroup/cooked-food-on-plate.jpg`,
    h: "ใช้อุณหภูมิไม่สูงเกิน",
    s: "ทำให้คุณสมบัติของกรดไขมันไม่ถูกทำลาย และไม่แปรสภาพ ทำให้น้ำมันหมูเชฟหมู ปลอดภัยทุกครั้งที่รับประทาน เพราะร่างกายย่อยสลายได้ง่ายและขับส่วนเกินออกได้หมด"
}, {
    img: `${process.env.PUBLIC_URL}/asset/img/our-product/imageGroup/cooked-food-on-plate.jpg`,
    h: "บ่มพักน้ำมันคัดกรองคุณภาพ",
    s: "การคัดเอาส่วนไขข้นออกจากการบ่มพักน้ำมันซึ่งถูกออกแบบโดยผู้เชี่ยวชาญ โดยทั่วไปเมื่อเราเจียวน้ำมันหมูออกมาแล้วปล่อยไว้ช่วงระยะเวลาหนึ่งจะเกิดตะกอนซึ่งเป็นไขหนักข้างใต้ ซึ่งส่วนนี้ทางโรงงานได้คัดแยกออก ให้เหลือเพียงน้ำมันส่วนที่ดีที่สุดซึ่งร่างกายสามารถย่อยได้ง่ายกว่า"
}, {
    img: `${process.env.PUBLIC_URL}/asset/img/our-product/imageGroup/cooked-food-on-plate.jpg`,
    h: "บรรจุขวดที่ออกแบบมาอย่างลงตัว",
    s: "เพื่อเก็บรักษาน้ำมันธรรมชาติโดยปราศจากการเติมสารเคมี ในขวดขาวสะอาดที่ปกป้องน้ำมันจากทั้งแสงและอากาศ ซึ่งเป็นปัจจัยทำให้เกิดกลิ่นหืน จึงมั่นใจได้ว่าเปิดขวดเชฟหมูเมื่อไหร่ก็หอมถูกใจ"
}]
export default class OurProduct extends Component {
    constructor(props) {
        super(props);
        this.textHeader = React.createRef();
        this.state = {
            activeHover: 0
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0 })
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => { /* ทำให้เป็น parallax */
        if (window.location.pathname === `${process.env.PUBLIC_URL}/ourproduct`) {
            if (this.textHeader.current !== null) {
                this.textHeader.current.style.marginTop = `${window.scrollY / 2}px`
            }
        }
    };

    render() {
        return (
            <div style={{ paddingBottom: "17%" }}>
                <section className="bg-header-our-product mb-5">
                    <Container className="h-100 position-relative">
                        <div className="text-header-area ">
                            <h1 className="text-header-animation"
                                ref={this.textHeader}
                            >Our Product</h1>
                            <div className="underline-animation-1" />
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/product-chefmoo.png`} alt="" className="chef-moo-product-left d-none d-xl-block" />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/product-chefmoo.png`} alt="" className="chef-moo-product-right d-none d-xl-block" />
                    </Container>
                </section>
                <section className="py-5 position-relative overflow-hidden">
                    <Container>
                        <Row className="position-relative">
                            <img src={`${process.env.PUBLIC_URL}/asset/img/img-1.png`} className="img-obj dot-rec" alt="" />
                            <div className="col-lg-5 text-center text-lg-start pb-2 pb-lg-0">
                                <img src={`${process.env.PUBLIC_URL}/asset/img/home/why-cook.jpg`} className="img-fluid pb-3 pb-md-0 " alt="" />
                            </div>
                            <div className="col-lg-7 ">
                                <h1 className="font-weight-bold text-center text-lg-left">ทำไมต้องเป็นน้ำมันหมู ?</h1>
                                <p className="text-lg-left text-left">
                                    จากการศึกษาวิจัยในปัจจุบันพบว่าการบริโภคไขมันในสัดส่วนที่เหมาะสมนั้นช่วยให้สุขภาพของเรานั้นดีขึ้น โดยธรรมชาติ ไขมันมีอยู่ 3 ประเภท ไขมันอิ่มตัว ไขมันไม่อิ่มตัวเชิงเดี่ยว และ ไขมันไม่อิ่มตัวเชิงซ้อน ซึ่งน้ำมันหมูมีครบทั้ง 3 ชนิด โดยมีมากสุดคือ ไขมันไม่อิ่มตัวเชิงเดี่ยว และ ไขมันอิ่มตัวรองลงมา ซึ่งกรดไขมันสองประเภทนี้ใช้ในการสร้างสมดุลฮอร์โมนให้กับร่างกาย ทั้งให้เรื่องของระบบเผาผลาญ ระบบประสาท และระบบไหลเวียนโลหิต</p>
                            </div>

                        </Row>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <div className="d-flex justify-content-center">
                            <div className="d-inline-block">
                                <h1 className="text-center mb-0">Detail Product</h1>
                                <div className="underline-header mb-4" />
                            </div>
                        </div>
                        <div className="text-center pb-3">
                            <video autoPlay muted loop controls id="myVideo" className="w-75">
                                <source src={`${process.env.PUBLIC_URL}/asset/video/Chef-Moo-Detail.mp4`} type="video/mp4" />
                            </video>
                        </div>
                        <Row>
                            {
                                detailProduct.map((items, index) => {
                                    return (
                                        <div className={`col-md-4 detail-active`} key={index}>
                                            <h2 className="text-md-left text-center">{items.h}</h2>
                                            <p className="text-md-left text-center">
                                                {items.s}</p>
                                        </div>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <div className="d-flex justify-content-center">
                            <div className="d-inline-block">
                                <h1 className="text-center mb-0">Process</h1>
                                <div className="underline-header mb-4" />
                            </div>
                        </div>
                        <div className="timeline">
                            <ul className="mb-0 pb-lg-3">
                                <li>
                                    <div className="px-3 pb-md-3 d-flex flex-column flex-md-row">
                                        <div className="col-md-6 col-12 order-1">
                                            <h5>ขนส่งจากโรงฆ่าถีงโรงงานโดยตรง</h5>
                                            <p>
                                                วัตถุดิบที่สดใหม่กว่าเพราะได้วัตถุดิบตรงจากโรงงานแปรรูปคุณภาพ ทำให้ผลิตน้ำมันหมูได้คุณภาพของอย่างเต็มที่ด้วยความสดใหม่
                                        </p>
                                        </div>
                                        <div className="col-md-6 col-12 d-flex align-items-center order-0">
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/our-product/truck.jpg`} className="img-fluid pb-3 pb-md-0 " alt="" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="px-3 pb-md-3 d-flex flex-column flex-md-row">
                                        <div className="col-md-6 col-12 d-flex align-items-center">
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/our-product/man-wearing-gray-t-shirt.jpg`} className="img-fluid pb-3 pb-md-0 " alt="" />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <h5>คัดกรองและแยกประเภท</h5>
                                            <p>
                                                การคัดเอาส่วนไขข้นออกจากการบ่มพักน้ำมันซึ่งถูกออกแบบโดยผู้เชี่ยวชาญ โดยทั่วไปเมื่อเราเจียวน้ำมันหมูออกมาแล้วปล่อยไว้ช่วงระยะเวลาหนึ่งจะเกิดตะกอนซึ่งเป็นไขหนักข้างใต้ ซึ่งส่วนนี้ทางโรงงานได้คัดแยกออก ให้เหลือเพียงน้ำมันส่วนที่ดีที่สุดซึ่งร่างกายสามารถย่อยได้ง่ายกว่า
                                        </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="px-3 pb-md-3 d-flex flex-column flex-md-row">
                                        <div className="col-md-6 col-12 order-1">
                                            <h5>เจียวในอุณหภูมิ 100 - 150 °C ระบบปิดควบควบคุมคุณภาพ</h5>
                                            <p>
                                                โดยทั่วไปเมื่อเราเจียวน้ำมันหมู ปล่อยไว้ระยะหนึ่งจะเกิดตะกอนซึ่งเป็นไขมันหนักข้างใต้ ซึ่งส่วนนี้ทางโรงงานได้คัดแยกออก ให้เหลือเพียงน้ำมันส่วนที่ดีที่สุดซึ่งร่างกายสามารถย่อยได้ง่ายกว่า
                                        </p>
                                        </div>
                                        <div className="col-md-6 col-12 d-flex align-items-center order-0">
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/our-product/fired.jpg`} className="img-fluid pb-3 pb-md-0 " alt="" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="px-3 pb-md-3 d-flex flex-column flex-md-row">
                                        <div className="col-md-6 col-12 d-flex align-items-center">
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/our-product/bottle.jpg`} className="img-fluid pb-3 pb-md-0 " alt="" />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <h5>บรรจุหลังจากตกไขแล้ว 1 ครั้ง เพื่อน้ำมันมวล “เบา”</h5>
                                            <p>
                                                แพ็คเกจจิ้งซึ่งออกแบบโดยนักโภชนาการ ซึ่งอ้างอิงข้อมูลการรักษาคุณภาพของไขมัน จากหลักการ 2 เลี่ยง คือ เลี่ยงแสง และ เลี่ยงอากาศ ซึ่งเป็นปัจจัยหลักในการเสื่อมคุณภาพของกรดไขมัน ฉะนั้นการออกแบบขวดขาวทึบและหัวปิดแบบซอสจึงเป็นการออกแบบที่ลงตัวที่สุดของน้ำมันหมูซึ่งจะมีความหนืดเล็กน้อยในอุณหภูมิห้อง
                                        </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="text-lg-center d-lg-block d-none">
                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/product-chefmoo.png`} className="" style={{ marginTop: "-16px", position: "absolute", transform: "translateX(-50%)" }} alt="" />
                        </div>
                    </Container>
                </section>
            </div>
        )
    }
}

import React, { Component } from 'react'
import {
    Container,
    Row,
    Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterShareButton,
    TwitterIcon,
    LineShareButton,
    LineIcon
} from 'react-share';
import { NavLink } from 'react-router-dom'
import Service from '../../Script/service';
import Moment from 'react-moment';
import LoadingPage from '../../Components/LoadingPage';

export default class SinglePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            subBlog: [],
            isLoading: false
        }
    }

    componentDidMount() {
        let _cat_id = [];
        this.setState({ isLoading: true })
        Service.getSingleBlog({ "slug_name_post": this.props.match.params.key })
            .then(res => {
                res.data.data[0].cat_blog.forEach((items, index) => {
                    _cat_id.push(items.term_id)
                })
                Service.getRelatedBlog({ cat_ids: _cat_id, id_blog: res.data.data[0].id, limit: 3 })
                    .then(response => {
                        window.scrollTo(0, 0)
                        this.setState({ data: res.data.data[0], subBlog: response.data.data, isLoading: false })
                    })
            })
    }

    handleChangeItems = (post_name) => {
        let _cat_id = [];
        this.setState({ isLoading: true })
        Service.getSingleBlog({ "slug_name_post": post_name })
            .then(res => {
                res.data.data[0].cat_blog.forEach((items, index) => {
                    _cat_id.push(items.term_id)
                })
                Service.getRelatedBlog({ cat_ids: _cat_id, id_blog: res.data.data[0].id, limit: 3 })
                    .then(response => {
                        window.scrollTo(0, 0)
                        this.setState({ data: res.data.data[0], subBlog: response.data.data, isLoading: false })
                    })
            })
    }

    render() {
        return (
            <div>
                <section className="content-post py-3" >
                    <Container>
                        <h1 className="pb-3">Blog</h1>
                        <Breadcrumb >
                            <BreadcrumbItem><strong>หน้าแรก</strong></BreadcrumbItem>
                            <BreadcrumbItem><strong>{this.state.data.cat_blog && this.state.data.cat_blog[0].name}</strong></BreadcrumbItem>
                            <BreadcrumbItem active><strong dangerouslySetInnerHTML={{ __html: this.state.data.title }}></strong></BreadcrumbItem>
                        </Breadcrumb>
                        <h3 dangerouslySetInnerHTML={{ __html: this.state.data.title }} className="py-3"></h3>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="img-thumnail">
                                    <img src={this.state.data.authornameimage} alt="" />
                                </div>
                                <small className="caption">{this.state.data.authorname} | <Moment format="DD MMM YYYY">{this.state.data.date}</Moment> </small>
                            </div>
                        </div>
                    </Container>
                </section>
                <section >
                    <Container>
                        <div>
                            <img src={this.state.data.img_blog_thumbnail} className="img-fluid mx-auto d-block py-3" alt="" />
                        </div>
                        <div className="content-detail-post" style={{ minHeight: "50vh" }} dangerouslySetInnerHTML={{ __html: this.state.data.description }}>

                        </div>
                        <Row>
                            <div className="col-lg-7 d-flex align-items-center py-5 social-content">
                                <strong>SHARE:</strong>
                                <FacebookShareButton url={`${document.location.href}`}>
                                    <FacebookIcon borderRadius={50} size={33.37} />
                                </FacebookShareButton>
                                <TwitterShareButton url={`${document.location.href}`}>
                                    <TwitterIcon borderRadius={50} size={33.37} />
                                </TwitterShareButton>
                                <LineShareButton url={`${document.location.href}`}>
                                    <LineIcon borderRadius={50} size={33.37} />
                                </LineShareButton>
                            </div>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row>
                            {
                                this.state.subBlog.length > 0 ?
                                    this.state.subBlog.map((items, index) => {
                                        return (
                                            <div className="col-lg-4 col-12 py-3" key={index}>
                                                <div className="my-3 warpper-card">
                                                    <NavLink to={`/single-post/${items.sub_url_name}`} onClick={(e) => this.handleChangeItems(items.sub_url_name)}>
                                                        <div>
                                                            <div className="mb-3">
                                                                <img src={items.img_blog_thumbnail} className="img-fluid" alt="" />
                                                            </div>
                                                            <h5 className="mt-0 mb-2 px-3 text-title-blog text-blue" dangerouslySetInnerHTML={{ __html: items.title }}></h5>
                                                            <small className="body-1 text-descriptionmore px-3" dangerouslySetInnerHTML={{ __html: items.descriptionmore }}></small>
                                                        </div>
                                                        <div className="py-3 px-3 d-flex align-items-center">
                                                            <div className="img-thumnail">
                                                                <img src={items.authornameimage} alt="" />
                                                            </div>
                                                            <small className="caption">{items.authorname} | <Moment format="DD MMM YYYY">{items.date}</Moment> </small>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                    (
                                        <div className="col-12">
                                            <h1 className="text-center py-5" style={{ color: "gray" }}>ยังไม่มีบทความอื่นๆ</h1>
                                        </div>
                                    )
                            }
                        </Row>
                    </Container>
                </section>
                <LoadingPage isOpen={this.state.isLoading} />
            </div>
        )
    }
}

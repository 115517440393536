import Joi from 'joi-browser'

const param = Joi.object().keys({
    subject: Joi.string(),
    name: Joi.string(),
    email: Joi.string().email({ minDomainAtoms: 2 }),
    tel: Joi.number(),
    message: Joi.string(),
})

export function Validate(obj) {
    return Joi.validate(obj, param)
}

import React, { Component } from 'react'
import './style.scss'
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Container
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
export default class NavbarComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: null,
            animeTop: "animate-scroll-top"
        }
        this.getPosClick = 0;
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    componentDidMount() {
        const _self = this;
        window.scrollTo(0, 0)
        window.onscroll = null;
        document.addEventListener('click', function (event) {
            if (event.clientX > 320) {
                _self.setState({ isOpen: false })
            }
        }, true)
        window.onscroll = function () {
            if (window.pageYOffset === 0) {
                _self.setState({ animeTop: "animate-scroll-top" })
            } else {
                _self.setState({ animeTop: "animate-scroll-down" })
            }
        };
    }

    componentWillUnmount() {

        window.onscroll = null;
    }

    closeNav = () => {
        window.scrollTo(0, 0)
        this.setState({ isOpen: false });
    }

    render() {
        return (
            <div>
                <Navbar color="light" light expand="lg" fixed="top" className={this.state.animeTop}>
                    <div className="w-100 d-block d-xl-none">
                        <div>
                            <div className="d-block d-xl-none">
                                <NavbarBrand className="mx-auto ">
                                    <img src={`${process.env.PUBLIC_URL}/asset/img/logo.svg`} alt="" className={`logo-img-web`} />
                                </NavbarBrand>
                            </div>
                        </div>
                        <div className="ml-auto d-block d-xl-none toggle-circle">
                            <NavbarToggler onClick={this.toggle} className="d-block ml-auto" />
                        </div>
                    </div>
                    <Container className={`${this.state.animeTop} d-xl-block d-none`}>
                        <Nav navbar className="align-items-center justify-content-center">
                            <div className="d-flex col-4 justify-content-end">
                                <NavItem>
                                    <NavLink exact to="/">HOME</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink exact to="/ourproduct">OUR PRODUCT</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink exact to="/aboutus">ABOUT US</NavLink>
                                </NavItem>
                            </div>
                            <div className="col-2">
                                <NavbarBrand to="/" className="d-none d-xl-flex mx-auto">
                                    <img src={`${process.env.PUBLIC_URL}/asset/img/logo.svg`} alt="" className={`logo-img-web`} />
                                </NavbarBrand>
                            </div>
                            <div className="d-flex col-4">
                                <NavItem>
                                    <NavLink exact to="/blog">BLOG</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink exact to="/recipe">RECIPE</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink exact to="/contact">CONTACT US</NavLink>
                                </NavItem>
                            </div>
                        </Nav>
                    </Container>

                </Navbar>
                <div className={`warpper-menu-silde d-block d-xl-none ${this.state.isOpen !== null ? `${this.state.isOpen ? "animate-slide-left" : "animate-slide-right"}` : "animate-slide-init"}`}>
                    <div className={`menu-side `}>
                        <div className="d-flex justify-content-between container-menu">
                            <h5>MENU</h5>
                            <i className="material-icons-round" onClick={this.toggle}>close</i>
                        </div>
                        <ul className="menu-list w-75">
                            <li>
                                <NavLink exact to="/" onClick={this.closeNav}>HOME</NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/ourproduct" onClick={this.closeNav}>OUR PRODUCT</NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/aboutus" onClick={this.closeNav}>ABOUT US</NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/blog" onClick={this.closeNav}>BLOG</NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/recipe" onClick={this.closeNav}>RECIPE</NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/contact" onClick={this.closeNav}>CONTACT US</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                {this.props.children}
            </div>
        )
    }
}

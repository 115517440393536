import React, { Component } from 'react'
import {
    Container,
    Row
} from 'reactstrap';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import LastedBlog from '../../Components/LastedBlog';
import { Draggable, gsap } from 'gsap/all';
import Touch from '../../Script/touch';
import Button from '../../Components/Button';
import { NavLink } from 'react-router-dom';
import Service from '../../Script/service';
import { ImpulseSpinner } from "react-spinners-kit";
export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeAnime: 0,
            slideActive: 0,
            data: [],
            isLoad: false
        }
        this.imgblack = React.createRef()
        this.dragSwip = null
        this.draggerSwip = null
        this.widthDrag = 0
        this.state_touch = false

    }
    componentDidMount() {
        window.scrollTo({ top: 0 })
        let _self = this
        gsap.registerPlugin(Draggable);
        this.draggerSwip = Draggable.create(this.dragSwip, {
            type: "x",
            bounds: ".wrapper-drag-area",
            onDrag: function () {
                _self.imgTopDrag.style.width = `${this.x}px`
            }
        });

        Service.getBlog({ cat_name: "recipe" })
            .then(res => {
                this.setState({ data: res.data.data, isLoad: true })
            })
    }


    handleScroll = () => {
        let _slideInit;

        if (window.location.pathname === `${process.env.PUBLIC_URL}/`) {
            //------------- Touch Section Compare (Scroll Touch Element) -------------//
            _slideInit = this.imgblack.current.offsetWidth / 2
            gsap.to(this.dragSwip, { x: _slideInit, duration: 2 });
            gsap.to(this.imgTopDrag, { width: _slideInit, duration: 2 });
            this.state_touch = true;
            //------------- End Touch Section Compare (Scroll Touch Element) -------------//
        }

    };

    handleClickCompare = (typeClick) => {
        let _slide = (typeClick === "oil") ? 0 : this.imgblack.current.offsetWidth
        gsap.to(this.dragSwip, { x: _slide, duration: 1 });
        gsap.to(this.imgTopDrag, { width: _slide, duration: 1 });
    }

    handleAnimate = (num) => {
        this.setState({ activeAnime: num })
    }

    render() {
        // let _self = this
        const slideProps = {
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            breakpoints: {
                640: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            },
            on: {
                slideChange: function () {
                    if (this.loopedSlides <= 3) {
                        this.autoplay.stop()
                    }
                }
            }
        };

        return (
            <div>
                <section className="position-relative banner-video">
                    <div className="bg-banner-header">
                         
                    </div>
                    {/* <video autoPlay muted loop id="myVideo">
                        <source src={`${process.env.PUBLIC_URL}/asset/video/bg-home.mp4`} type="video/mp4" />
                    </video> */}
                    <svg viewBox="0 0 500 50" fill="white" className="position-absolute">
                        <path d="m 0 0 h 500 v 50 h -500 v -50 q 250 50 500 0" />
                    </svg>
                </section>
                <section className="pb-3" style={{ marginTop: "-150px" }}>
                    <Container>
                        <div className=" img-select-animate">
                            <Row className="pb-5 d-none d-xl-flex">
                                <div className="col-4 d-flex flex-col justify-content-around">
                                    <div className="ele-product justify-content-end">
                                        <div className="ele-descript">
                                            <div className="text-right">
                                                <h4>มีโอเมก้า 3</h4>
                                                <p>ช่วยลดความเครียด</p>
                                            </div>
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/description.svg`} className="flip" alt="" />
                                        </div>
                                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/brain.svg`} alt="" />
                                    </div>
                                    <div className="ele-product justify-content-end">
                                        <div className="ele-descript">
                                            <div className="text-right">
                                                <h4>กรดไขมันสร้าง HDL</h4>
                                                <p>ช่วยลดคอสเลสตอรอล</p>
                                            </div>
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/description.svg`} className="flip" alt="" />
                                        </div>
                                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/blood.svg`} alt="" />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <img src={`${process.env.PUBLIC_URL}/asset/img/home/product-chefmoo.png`} className="h-100 chef-moo-product" alt="" />
                                </div>
                                <div className="col-4 flex-col justify-content-around">
                                    <div className="ele-product justify-content-end">
                                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/heart.svg`} alt="" />
                                        <div className="ele-descript">
                                            <div className="text-left">
                                                <h4>มีโอเมก้า 9</h4>
                                                <p>ดีต่อหลอดเหลือด</p>
                                            </div>
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/description.svg`} alt="" />
                                        </div>
                                    </div>
                                    <div className="ele-product justify-content-end">
                                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/factory.svg`} alt="" />
                                        <div className="ele-descript">
                                            <div className="text-left">
                                                <h4>มีโรงงาน</h4>
                                                <p>ผลิตตามมาตราฐาน</p>
                                            </div>
                                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/description.svg`} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <div className="d-block d-xl-none pb-5">
                                <img src={`${process.env.PUBLIC_URL}/asset/img/home/product-chefmoo.png`} className="chef-moo-product" style={{ height: "350px" }} alt="" />
                            </div>
                            <div className="border pos-relative">
                                <img src={`${process.env.PUBLIC_URL}/asset/img/home/text-1.png`} className="pos-absolute" style={{ transform: "translate(-40%,-50%)" }} alt="" />
                                <img src={`${process.env.PUBLIC_URL}/asset/img/home/text-1.png`} className="pos-absolute" style={{ transform: "translate(40%,50%) rotate(180deg)", right: 0, bottom: 0 }} alt="" />
                                <h1 className="mx-3 my-5 text-center">น้ำมันหมู Chef Moo น้ำมันจากธรรมชาติ <br />สดใหม่ หอมอร่อย เผาผลาญง่าย ไม่สะสม ดีต่อสุขภาพ<br />ต้อง Chef Moo</h1>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className="py-5 pos-relative">
                    <Container className="overflow-hidden">
                        <div className="d-flex justify-content-center mb-2">
                            <div className="d-inline-block">
                                <h1 className="text-center mb-0">Product detail</h1>
                                <div className="underline-header mb-4" />
                            </div>
                        </div>
                        <Row className="pb-5">
                            <div className="col-lg-7 order-1 order-lg-1" style={{ position: "unset" }}>
                                <Touch peract={2000} positionDetect="up" animate="animated fadeInRight">
                                    <h2 className="font-weight-bold text-center text-lg-left" animate="">ดีต่อสุขภาพ ปลอดสารเคมี</h2>
                                </Touch>
                                <Touch peract={800} positionDetect="up" animate="animated fadeInRight">
                                    <p className="text-center text-lg-left">
                                        <strong>น้ำมันหมู By Chef Moo </strong>เป็นน้ำมันหมูเกรดพรีเมี่ยม ที่มีงานวิจัย และข้อมูลทางวิชาการรองรับว่า เป็นกลุ่มน้ำมันที่บริสุทธิ์และปลอดภัยต่อร่างกาย เนื่องจากไม่มีการผ่านกระบวนการผลิตทางเคมี ตอบโจทย์กับคนรุ่นใหม่ที่รักสุขภาพ </p>
                                    <div className="d-flex justify-content-center justify-content-lg-start">
                                        <NavLink to={`/ourproduct`} className="d-inline-block"><Button >อ่านเพิ่มเติม</Button></NavLink>
                                    </div>
                                </Touch>
                            </div>
                            <div className="col-lg-5 order-0 order-lg-0 text-center text-lg-start " style={{ position: "unset" }}>
                                <Touch peract={250} positionDetect="up" animate="animated fadeInLeft" >
                                    <img src={`${process.env.PUBLIC_URL}/asset/img/home/good-for-heal.jpg`} className="img-fluid pb-3 pb-lg-0" alt="" />
                                </Touch>
                            </div>

                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/curve/01-element-back-orenge.svg`} className="pos-absolute right-0 d-none d-lg-block" style={{ height: "50%", zIndex: "-1", marginTop: "-100px" }} alt="" />
                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/ingred.png`} className="pos-absolute d-none d-xl-block" style={{ height: "40%", right: "5%", top: "50%", zIndex: "-1" }} alt="" />
                        </Row>
                        <Row className="pb-5">

                            <div className="col-lg-7 order-lg-0 order-1" style={{ position: "unset" }}>
                                <Touch peract={2000} positionDetect="up" animate="animated fadeInLeft">
                                    <h2 className="font-weight-bold text-center text-lg-right">ทำไมถึงต้องใช้ “น้ำมันหมู”</h2>
                                </Touch>
                                <Touch peract={1500} positionDetect="up" animate="animated fadeInLeft">
                                    <p className="text-center text-lg-right">
                                        <strong>น้ำมันหมู</strong> มีการเผาผลาญที่ง่ายกว่า เมื่อถูกความร้อนสูงเป็นเวลานาน จะไม่เปลี่ยนเป็นไขมันทรานช์ นั่นจึงเป็นเหตุผลว่าทำไมผู้บริโภคส่วนใหญ่หันมาใช้น้ำมันหมู</p>
                                    <div className="d-flex justify-content-center justify-content-lg-end">
                                        <NavLink to={`/ourproduct`} className="d-inline-block"><Button >อ่านเพิ่มเติม</Button></NavLink>
                                    </div>
                                </Touch>

                            </div>
                            <div className="col-lg-5 order-lg-1 order-0 text-center text-lg-start" style={{ position: "unset" }}>
                                <Touch peract={250} positionDetect="up" animate="animated fadeInRight">
                                    <img src={`${process.env.PUBLIC_URL}/asset/img/home/why-cook.jpg`} className="img-fluid pb-3 pb-lg-0" alt="" />
                                </Touch>

                            </div>
                            <div className="d-none d-lg-block">
                                <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/curve/04-element-back-orenge.svg`} className="pos-absolute left-0 d-none d-lg-block" style={{ height: "35%", zIndex: "-1", marginTop: "-45%" }} alt="" />
                                <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/curve/02-element-back-orenge.svg`} className="pos-absolute left-0" style={{ height: "40%", bottom: "20%", zIndex: "-1" }} alt="" />
                                <img src={`${process.env.PUBLIC_URL}/asset/img/home/Element/curve/02-product-chef-moo.png`} className="pos-absolute left-0 d-none d-xl-block" style={{ height: "40%", bottom: "25%", zIndex: "-1" }} alt="" />
                            </div>
                        </Row>
                        <Row className="pt-5">
                            <Touch className="col-12 col-lg-10 text-center mx-auto" peract={70} funcact={this.handleScroll} positionDetect="up">
                                <div className="position-relative d-inline-block w-100 wrapper-drag-area ">
                                    <div className="wrapper-image-top-drag" ref={refs => this.imgTopDrag = refs}>
                                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/compare-lard.jpg`} alt="" />
                                    </div>

                                    <img src={`${process.env.PUBLIC_URL}/asset/img/home/compare-oil.jpg`} className="img-fluid w-100" alt="" ref={this.imgblack} />
                                    <div className="drag-img" ref={refs => this.dragSwip = refs} />
                                </div>

                                <div className="d-flex mx-auto justify-content-around py-3 compare-button mb-3">
                                    <Button onClick={e => this.handleClickCompare("lard")}>น้ำมันหมู</Button>
                                    <Button onClick={e => this.handleClickCompare("oil")}>น้ำมันพืช</Button>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="d-inline-block">
                                        <h1 className="text-center mb-0">น้ำมันหมูดีกว่าน้ำมันพืชยังไง ?</h1>
                                        <div className="underline-header mb-3" />
                                    </div>
                                </div>
                                <p>
                                    น้ำมันพืชในอุตสาหกรรมส่วนมากจะต้องผ่านกระบวนการสกัดด้วยสารเคมีทำให้โครงสร้างของกรดไขมันธรรมชาติบางส่วนผิดเพี้ยนไปจากรูปแบบดั้งเดิมจึงทำให้ร่างกายของมนุษย์อาจไม่สามารถย่อยสลายหรือขับออกมาได้หมด ส่วนน้ำมันหมูนั้นไม่เพียงแค่ไม่ต้องผ่านกระบวนการทางเคมี แต่ยังเป็นแหล่งพลังงานจากไขมัน ซึ่งมนุษย์บริโภคกันมาอย่างยาวนานทำให้ขับออกง่ายไม่สะสม
                                </p>
                            </Touch>
                        </Row>
                    </Container>
                </section>
                <section className="pt-5 bg-home-section-recipe">
                    <Container className="position-relative">
                        <h1 className="text-center pb-3 text-white">Recipe</h1>
                        {
                            this.state.isLoad ?
                                (
                                    this.state.data.length > 0 ?
                                        <Swiper {...slideProps}>
                                            {
                                                this.state.data.map((items, index) => {
                                                    return (
                                                        <div className="position-relative" key={index}>
                                                            <NavLink to={`/single-post/${items.sub_url_name}`}>
                                                                <div className="warpper-img-in-dish d-flex align-items-center">
                                                                    <img src={items.img_blog_thumbnail} className="img-fluid" alt="" />
                                                                </div>
                                                            </NavLink>
                                                            <img src={`${process.env.PUBLIC_URL}/asset/img/home/dish-recipe.svg`} className="w-100" alt="" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Swiper> :
                                        <div className="text-center py-5">
                                            <h1 className="text-white">
                                                Coming Soon ...
                                            </h1>
                                        </div>
                                ) : (
                                    <div className="d-flex justify-content-center py-5">
                                        <ImpulseSpinner
                                            size={60}
                                            frontColor="#e54a26"
                                            backColor="#ffd600"
                                            loading={!this.state.isLoad}
                                        />
                                    </div>
                                )

                        }

                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/table-recipe.svg`} className="img-fluid" alt="" />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/product-chefmoo.png`} className="position-absolute bottom-recipe d-none d-md-block" alt="" />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/home/chef-moo.svg`} className="position-absolute chef-moo-recipe d-none d-md-block" alt="" />
                    </Container>
                </section>
                <section>
                    <LastedBlog />
                </section>
            </div >
        )
    }
}

import React, { Component } from 'react';

export default class None extends Component {
    render() {
        return (
            <div style={{ minHeight: "100vh" }} className="d-flex justify-content-center align-items-center">
                <div>
                    <div className="d-flex justify-content-center pb-3">
                        <i style={{ fontSize: "70px" }} class="material-icons icon-image-preview d-inline-block mx-auto">sentiment_very_dissatisfied</i>
                    </div>
                    <h3 className="text-center">404</h3>
                    <h5 className="text-center pb-2">Page not found</h5>
                    <p className="text-center w-50 d-block mx-auto">This Page you are looking fot doesn't exist or an other error occurred. Go back, or head over to Home Page to choose a new direction.</p>
                </div>
            </div>
        )
    }
}

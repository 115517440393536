import React, { Component } from 'react'
import {
    Container,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink
} from 'reactstrap';
import GoogleMapReact from 'google-map-react'
import ButtonBubble from '../../Components/Button';
import { Validate } from '../../Components/validate';
import Service from '../../Script/service';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import swal from '@sweetalert/with-react'
export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.textHeader = React.createRef();
        this.state = {
            tokenKey: "",
            data: {
                name: "",
                email: "",
                tel: "",
                message: "",
                subject: "ติดต่อสอบถาม"
            },
            valid: {},
            alert: null
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0 })
        window.addEventListener('scroll', this.handleScroll, true);
        loadReCaptcha("6LfGTt4UAAAAAHgYvYuPgGD6CmUQs6LhFlnWIFje");
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (window.location.pathname === `${process.env.PUBLIC_URL}/contact`) {
            if (this.textHeader.current !== null) {
                this.textHeader.current.style.marginTop = `${window.scrollY / 2}px`
            }
        }
    };

    renderMarkers(map, maps) {
        new maps.Marker({
            position: { lat: 13.5603758, lng: 100.6206247 },
            map,
            title: 'Chef-Moo'
        });
    }

    verifyCallback = (recaptchaToken) => {
        this.setState({ tokenKey: recaptchaToken })
    }

    onDismiss = () => { this.setState({ alert: null }) }

    handleChange = (e, state_name) => {
        this.onDismiss()

        let _data = this.state.data
        _data[state_name] = e.target.value
        const validate = Validate(this.state.data)
        if (validate.error !== null) {
            for (var i = 0; i < validate.error.details.length; i++) {
                this.setState({ data: _data, valid: { [validate.error.details[i].path[0]]: true } })
            }
        } else {
            this.setState({ data: _data, valid: {} })
        }
    }

    sendMail = () => {
        if (Validate(this.state.data).error === null) {
            Service.sendMailContact({ ...this.state.data, recaptcha: this.state.tokenKey })
                .then(response => {
                    swal({
                        content: (
                            <div className="text-center pt-3">
                                <h3>ส่งอีเมล</h3>
                                <p>ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว</p>
                            </div>
                        ),
                        icon: "success"
                    })
                        .then(() => {
                            window.location.reload();
                        })
                    let _data = this.state.data
                    _data = { ..._data, name: "", email: "", tel: "", message: "" }
                    this.setState({ data: _data })
                })
                .catch((err) => {
                    swal({
                        content: (
                            <div className="text-center pt-3">
                                <h3>ส่งอีเมล</h3>
                                <p>ไม่สามารถส่งอีเมลได่</p>
                            </div>
                        ),
                        icon: "error"
                    })
                        .then(() => {
                            window.location.reload();
                        })
                })
        } else {
            this.setState({ alert: "กรุณากรอกข้อมูลให้ครบถ้วน" })
        }
    }
    render() {
        return (
            <div>
                <section className="bg-header-our-product mb-5">
                    <Container className="h-100 position-relative">
                        <div className="text-header-area ">
                            <h1 className="text-header-animation" ref={this.textHeader}>Contact Us</h1>
                            <div className="underline-animation-1" />
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/asset/img/contact/05-chef-moo.svg`} className="pos-absolute animated fadeIn slow d-none d-xl-block" style={{ height: "140%", top: "50px", right: 0 }} alt="" />
                        <img src={`${process.env.PUBLIC_URL}/asset/img/contact/mail-chef-moo.svg`} className="pos-absolute animated fadeIn slow d-none d-xl-block" style={{ height: "40%", top: "50%", transform: "translateY(-40%) rotate(45deg)", left: "100px" }} alt="" />
                        {/* <img src={`${process.env.PUBLIC_URL}/asset/img/Meal-PNG-Download-Image.png`} alt="" className="food-sprint" /> */}
                    </Container>
                </section>
                <section>
                    <Container>
                        <div className="warp-contact-from ">
                            <Row>
                                <div className="col-12 col-lg-6 pt-5">
                                    <div className="text-center">
                                        <h2 className="underline-contact d-inline-block ">
                                            Contact Us
                                        </h2>
                                    </div>
                                    <Form className="px-3 px-lg-5 pb-5">
                                        <FormGroup className={'validate-input ' + (this.state.valid.name ? 'alert-validate' : '')}>
                                            <Label for="fullname" className="font-weight-bold">Name</Label>
                                            <Input invalid={this.state.valid.name ? true : false} type="text" name="fullname" id="fullname" placeholder="Full Name" onChange={e => this.handleChange(e, "name")} value={this.state.data.name} />
                                        </FormGroup>
                                        <FormGroup className={'validate-input ' + (this.state.valid.email ? 'alert-validate' : '')}>
                                            <Label for="email" className="font-weight-bold">E-Mail</Label>
                                            <Input invalid={this.state.valid.email ? true : false} type="email" name="email" id="email" placeholder="Your Mail" onChange={e => this.handleChange(e, "email")} value={this.state.data.email} />
                                        </FormGroup>
                                        <FormGroup className={'validate-input ' + (this.state.valid.tel ? 'alert-validate' : '')}>
                                            <Label for="phone" className="font-weight-bold">Phone</Label>
                                            <Input invalid={this.state.valid.tel ? true : false} type="tel" name="phone" id="tel" placeholder="Phone Number" onChange={e => this.handleChange(e, "tel")} value={this.state.data.tel} />
                                        </FormGroup>
                                        <FormGroup className={'validate-input ' + (this.state.valid.message ? 'alert-validate' : '')}>
                                            <Label for="message" className="font-weight-bold">Message</Label>
                                            <Input invalid={this.state.valid.message ? true : false} type="textarea" name="message" id="exampleText" placeholder="Your Message" className="text-message" onChange={e => this.handleChange(e, "message")} value={this.state.data.message} />
                                        </FormGroup>
                                        <ButtonBubble className="text-center" onClick={() => this.sendMail()}>Send...</ButtonBubble>
                                        <ReCaptcha
                                            sitekey="6LfGTt4UAAAAAHgYvYuPgGD6CmUQs6LhFlnWIFje"
                                            verifyCallback={(res) => this.verifyCallback(res)}
                                        />
                                    </Form>
                                </div>

                                <div className="col-12 col-lg-6 google-map-contact-form">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyCheRot8a2v6ihzAPqlpIFZNe4atTnX444" }}
                                        defaultCenter={{ lat: 13.5603758, lng: 100.6206247 }}
                                        defaultZoom={16}
                                        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        style={{ height: "100%" }}
                                    >
                                    </GoogleMapReact>
                                </div>
                            </Row>
                        </div>
                        {/* <Row className="py-5 d-md-flex">
                            <div className="col-12 col-sm-6 col-md-4 d-flex">
                                <div>
                                    <i className="material-icons-outlined px-3">phone</i>
                                </div>
                                <div>
                                    <h6>Phone</h6>
                                    <p className="d-flex">TELL:<NavLink className="p-0" href="tel:02-077-8152">02-077-8152</NavLink></p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 d-flex">
                                <div>
                                    <i className="material-icons-outlined px-3">mail</i>
                                </div>
                                <div>
                                    <h6>E-mail</h6>
                                    <p><NavLink className="p-0" href="mailto:pppoil@gmail.com">pppoil@gmail.com</NavLink></p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-flex">
                                <div>
                                    <i className="material-icons-outlined px-3">room</i>
                                </div>
                                <div>
                                    <h6>Address</h6>
                                    <p><NavLink className="p-0" href="https://www.google.com/maps/place/PPP+Oil/@13.5599924,100.6169101,16.25z/data=!4m5!3m4!1s0x311d589d4435fc37:0x508129112494b56!8m2!3d13.5603758!4d100.6206247" target="_blank">เลขที่ 1696 หมู่ 5 ตำบล ท้ายบ้านใหม่ อำเภอเมืองสมุทรปราการ สมุทรปราการ 10280</NavLink></p>
                                </div>
                            </div>
                        </Row> */}
                    </Container>
                </section>
            </div>
        )
    }
}

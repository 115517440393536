import React, { Component } from 'react'
import {
    Container,
    Row,
    NavLink
} from 'reactstrap';
import '../../style/util.scss'
import './style.scss'
import {
    faLine,
    faFacebook
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Footer extends Component {
    render() {
        return (
            <section className="footer-warp mt-5 pos-relative">
                <div className="py-5">
                    <Container className="pt-5">
                        <h2 className="text-center pb-3 text-orange">Chef Moo</h2>
                        <Row className="py-3">
                            <div className="col-md-4 d-flex justify-content-center">
                                <p className="subheader text-orange">TELL :</p>
                                <p className="pl-2"><NavLink className="p-0 hover-footer text-orange" href="tel:02-077-8152">02-077-8152</NavLink></p>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <p className="subheader text-orange">E-mail :</p>
                                <p className="pl-2"><NavLink className="p-0 text-white hover-footer" href="mailto:pppoil.co@gmail.com">pppoil.co@gmail.com</NavLink></p>
                            </div>
                            <div className="col-md-4 d-block text-center text-md-left d-md-flex justify-content-center">
                                <p className="subheader text-nowrap mb-0 mb-md-3 text-orange" >Address :</p>
                                <div className="pl-2">
                                    <p><NavLink className="p-0 text-white hover-footer" href="https://www.google.com/maps/place/PPP+Oil/@13.5599924,100.6169101,16.25z/data=!4m5!3m4!1s0x311d589d4435fc37:0x508129112494b56!8m2!3d13.5603758!4d100.6206247" target="_blank">เลขที่ 1696 หมู่ 5 ตำบล ท้ายบ้านใหม่ อำเภอเมืองสมุทรปราการ สมุทรปราการ 10280</NavLink></p>
                                </div>
                            </div>
                        </Row>
                        <div className="d-flex justify-content-center social-icon-footer ">
                            <a href="https://www.facebook.com/chefmooth/" className="text-orange hover-footer" target="_blank" rel="noopener noreferrer" alt="">
                                <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                            <a href="http://line.me/ti/p/@ChefMooTh" className="text-orange hover-footer" target="_blank" rel="noopener noreferrer" alt="">
                                <FontAwesomeIcon icon={faLine} size="2x" />
                            </a>
                        </div>
                    </Container>
                </div>
                <div className="copyright-footer">
                    <Container>
                        <small className="text-white">
                            Copyright © 2019 All rights reserved by Chef Moo Design by <a className="text-white" target="_blank" alt="" rel="noopener noreferrer" href="https://www.itreelife.com"><span>Itreelife.</span></a>
                        </small>
                    </Container>
                </div>
            </section>
        )
    }
}

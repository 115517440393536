import React, { Component } from 'react'
import './style/main.scss';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/util.scss'
import { ParallaxProvider } from 'react-scroll-parallax';

import HomePage from './Containers/Home';
import OurProduct from './Containers/Ourproduct';
import AboutUs from './Containers/Aboutus';
import Blog from './Containers/Blog';
import Recipe from './Containers/Recipe';
import SinglePage from './Containers/SingglePage';
import Contact from './Containers/Contact';
import NonePage from './Containers/NonePage'

import NavbarComponent from './Components/Navbar';
import FooterComponent from './Components/Footer';
export default class App extends Component {
  render() {
    return (
      <ParallaxProvider>
        <Router basename={`${process.env.PUBLIC_URL}`}>
          <NavbarComponent>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/ourproduct" component={OurProduct} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/recipe" component={Recipe} />
              <Route exact path="/single-post/:key" component={SinglePage} />
              <Route exact path="/contact" component={Contact} />
              <Route component={NonePage} />
            </Switch>
            <FooterComponent />
          </NavbarComponent>
        </Router>
      </ParallaxProvider>
    )
  }
}

